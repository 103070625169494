<template>
  <v-card rounded="lg" class="transparent">
    <!-- <v-card-title class="text-black pt-0 pb-0 px-0 fs-20 font-weight-bold">Inventori</v-card-title> -->
    <div id="settings" class="col-md-8 mx-auto">
      <div class="mb-3">
        <div class="text-black text-h5 pt-0 px-0 fs-20 font-weight-bold mb-0">
          Pengaturan
        </div>
      </div>
      <v-tabs color="primary" left>
        <v-tab v-if="roles.name != 'admin'" class="text-capitalize mb-2"
          >Verifikasi Perusahaan</v-tab
        >
        <v-tab class="text-capitalize mb-2">Akun</v-tab>
        <v-tab class="text-capitalize mb-2">Subdomain</v-tab>

        <!-- KONTEN -->
        <v-tab-item v-if="roles.name != 'admin'" class="mt-8">
          <v-card
            class="mx-auto pa-0 mb-3"
            elevation="2"
            style="border-radius: 8px; border: 1px solid #e2e8f0"
          >
            <v-card-title class="text-h5"> Profil Perusahaan </v-card-title>
            <v-form ref="form" v-model="valid">
              <v-alert v-show="alert_error" type="error" text outlined>
                {{ message }}
              </v-alert>
              <v-alert v-show="alert_success" type="success" text outlined>
                {{ message }}
              </v-alert>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <div class="text-body-2 mb-2 text-black mt-3">
                      Nama Perusahaan <span class="error--text">*</span>
                    </div>
                    <v-text-field
                      v-model="form.company_name"
                      required
                      class="rounded"
                      :rules="[
                        ...rules('Nama Perusahaan', 'required'),
                        validate,
                      ]"
                      :readonly="form.company_verified != 'unverified'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <div class="text-body-2 mb-2 text-black mt-3">
                      NIB (Nomor Induk Berusaha)
                      <span class="error--text">*</span>
                    </div>
                    <v-text-field
                      type="number"
                      v-model="form.nib"
                      required
                      class="rounded"
                      :rules="[
                        ...rules('Nomor Induk Berusaha', 'required'),
                        ...nibRules,
                        validate,
                      ]"
                      :readonly="form.company_verified != 'unverified'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <div class="text-body-2 mb-2 text-black mt-3">
                      NPWP (Nomor Pokok Wajib Pajak) Perusahaan
                      <span class="error--text">*</span>
                    </div>
                    <v-text-field
                      type="number"
                      v-model="form.npwp"
                      required
                      class="rounded"
                      :rules="[
                        ...rules('NPWP', 'required'),
                        ...npwpRules,
                        validate,
                      ]"
                      :readonly="form.company_verified != 'unverified'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <div class="text-body-2 mb-2 text-black mt-3">
                      NIK (Nomor Induk Kependudukan) Direktur
                      <span class="error--text">*</span>
                    </div>
                    <v-text-field
                      type="number"
                      v-model="form.ktp"
                      required
                      class="rounded"
                      :rules="nikRules"
                      :readonly="form.company_verified != 'unverified'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <div class="text-body-2 mb-2 text-black mt-3">
                      Alamat Perusahaan <span class="error--text">*</span>
                    </div>
                    <v-textarea
                      v-model="form.address"
                      required
                      class="rounded"
                      :rules="[
                        ...rules('Alamat Perusahaan', 'required'),
                        validate,
                      ]"
                      :readonly="form.company_verified != 'unverified'"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-expansion-panels class="elevation-2 mb-8">
                      <v-expansion-panel>
                        <v-expansion-panel-header class="text-h6">
                          Logo Perusahaan <span class="error--text">*</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="text-center">
                          <img
                            :src="form.logo"
                            style="
                              max-width: 100%;
                              height: 250px;
                              object-fit: contain;
                            "
                            class="mb-2"
                          />
                          <input
                            v-if="form.company_verified === 'unverified'"
                            id="inputfile"
                            type="file"
                            ref="FileInputLogo"
                            @change="onFileSelectLogo"
                            accept="image/png,image/jpg,image/JPEG,,image/svg+xml"
                            name="attachment"
                            class="box-file"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels class="elevation-2 mb-8">
                      <v-expansion-panel>
                        <v-expansion-panel-header class="text-h6">
                          Scan / Foto NIK <span class="error--text">*</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="text-center">
                          <img
                            v-if="form.ktp_type === 'image'"
                            :src="form.ktp_image"
                            style="
                              max-width: 100%;
                              height: 250px;
                              object-fit: contain;
                            "
                            class="mb-2"
                          />
                          <div
                            v-else-if="form.ktp_type === 'pdf'"
                            style="
                              max-width: 100%;
                              height: 250px;
                              object-fit: contain;
                            "
                            class="mb-2"
                          >
                            <v-icon class="mr-2">ri-file-text-fill</v-icon
                            ><a
                              :href="getBlobUrl(form.ktp_image)"
                              target="_blank"
                              >Document-ktp.pdf</a
                            >
                          </div>
                          <div v-else></div>
                          <input
                            v-if="form.company_verified === 'unverified'"
                            id="inputfile"
                            type="file"
                            ref="FileInputNIK"
                            @change="onFileSelectNIK"
                            accept="image/png,image/jpg,image/JPEG,application/pdf,image/svg+xml"
                            name="attachment"
                            class="box-file"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels class="elevation-2 mb-8">
                      <v-expansion-panel>
                        <v-expansion-panel-header class="text-h6">
                          Scan / Foto NIB <span class="error--text">*</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="text-center">
                          <img
                            v-if="form.nib_type === 'image'"
                            :src="form.nib_image"
                            style="
                              max-width: 100%;
                              height: 250px;
                              object-fit: contain;
                            "
                            class="mb-2"
                          />
                          <div
                            v-else-if="form.nib_type === 'pdf'"
                            style="
                              max-width: 100%;
                              height: 250px;
                              object-fit: contain;
                            "
                            class="mb-2"
                          >
                            <v-icon class="mr-2">ri-file-text-fill</v-icon
                            ><a
                              :href="getBlobUrl(form.nib_image)"
                              target="_blank"
                              >Document-nib.pdf</a
                            >
                          </div>
                          <div v-else></div>
                          <input
                            v-if="form.company_verified === 'unverified'"
                            id="inputfile"
                            type="file"
                            ref="FileInputNIB"
                            @change="onFileSelectNIB"
                            accept="image/png,image/jpg,image/JPEG,application/pdf,image/svg+xml"
                            name="attachment"
                            class="box-file"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-expansion-panels class="elevation-2 mb-8">
                      <v-expansion-panel>
                        <v-expansion-panel-header class="text-h6">
                          Scan / Foto NPWP <span class="error--text">*</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="text-center">
                          <img
                            v-if="form.npwp_type === 'image'"
                            :src="form.npwp_image"
                            style="
                              max-width: 100%;
                              height: 250px;
                              object-fit: contain;
                            "
                            class="mb-2"
                          />
                          <div
                            v-else-if="form.npwp_type === 'pdf'"
                            style="
                              max-width: 100%;
                              height: 250px;
                              object-fit: contain;
                            "
                            class="mb-2"
                          >
                            <v-icon class="mr-2">ri-file-text-fill</v-icon
                            ><a
                              :href="getBlobUrl(form.npwp_image)"
                              target="_blank"
                              >Document-npwp.pdf</a
                            >
                          </div>
                          <div v-else></div>
                          <input
                            v-if="form.company_verified === 'unverified'"
                            id="inputfile"
                            type="file"
                            ref="FileInputNPWP"
                            @change="onFileSelectNPWP"
                            accept="image/png,image/jpg,image/JPEG,application/pdf,image/svg+xml"
                            name="attachment"
                            class="box-file"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions id="btn-disable" style="background: #f1f5f9">
                <v-spacer></v-spacer>
                <v-btn text large color="primary" :to="{ name: 'dashboard' }">
                  Batal
                </v-btn>
                <v-btn
                  dark
                  color="primary"
                  large
                  :loading="submitting"
                  @click="updateProfile()"
                  :disabled="
                    form.company_verified != 'unverified' ||
                    form.domain === null
                  "
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-tab-item>

        <v-tab-item class="mt-8">
          <div class="box-fullname mb-5">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="text-body-1 mb-2 text-black">Nama Lengkap</div>
                <v-text-field v-model="fullname" outlined></v-text-field>
              </v-col>
            </v-row>
            <!-- v-if="akun.fullname === null || akun.fullname === ''" -->
            <v-btn
              class="mt-3"
              color="primary"
              dark
              :disabled="akun.fullname === null"
              :loading="submitting"
              @click="saveFullname()"
            >
              Simpan
            </v-btn>
          </div>
          <v-divider></v-divider>

          <div class="box-fullname mb-5 mt-4">
            <v-row>
              <v-col class="py-0" cols="12">
                <div class="subtitle-1 mb-2 text-black mt-3">Provinsi</div>
                <v-select
                  :disabled="locationLoading || locationDisabled"
                  v-model="province_id"
                  filled
                  required
                  outlined
                  hide-details="auto"
                  :items="provinces"
                  item-text="name"
                  item-value="id"
                  @change="getCities($event)"
                >
                </v-select>
              </v-col>

              <v-col class="py-0" cols="12">
                <div class="subtitle-1 mb-2 text-black mt-3">
                  Kabupaten/Kota
                </div>
                <v-select
                  :disabled="locationLoading || locationDisabled"
                  v-model="city_id"
                  filled
                  required
                  outlined
                  hide-details="auto"
                  :items="cities"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>

              <v-col>
                <v-btn
                  class="mt-3"
                  color="primary"
                  dark
                  :disabled="locationDisabled"
                  :loading="locationLoading"
                  @click="saveLocationData()"
                >
                  Simpan
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>

          <div class="box-email my-5">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="text-subtitle-1 mb-2 text-black">Alamat Email</div>
                <div class="text-body-1 mb-2 text-muted">{{ akun.email }}</div>
                <p class="text-black font-weight-bold">
                  Ingin merubah alamat email Anda?
                </p>
              </v-col>
            </v-row>
            <!-- v-if="akun.fullname === null || akun.fullname === ''" -->
            <a
              href="https://wa.me/628112955660"
              target="_blank"
              class="mt-3 v-btn v-btn--is-elevated v-btn--has-bg theme--light elevation-2 v-size--default"
            >
              Hubungi CS
            </a>
          </div>
          <v-divider></v-divider>
          <div class="box-password my-5">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="text-subtitle-1 mb-2 text-black">Kata Sandi</div>
                <div class="text-body-1 mb-2 text-muted">
                  Tetapkan kata sandi unik untuk melindungi akun pribadi Anda.
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="text-body-1 mb-2 text-black">
                  Kata Sandi Saat Ini
                </div>
                <v-text-field
                  v-model="current_password"
                  outlined
                  :rules="crpswRules"
                  required
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show = !show"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="text-body-1 mb-2 text-black">Kata Sandi Baru</div>
                <v-text-field
                  v-model="password_new"
                  outlined
                  :rules="pswRules"
                  required
                  :type="show2 ? 'text' : 'password'"
                  :append-icon="show2 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show2 = !show2"
                  hide-details="auto"
                ></v-text-field>
                <!-- :type="show2 ? 'text' : 'password'"
                  append-icon="show2 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show2 = !show2" -->
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="text-body-1 mb-2 text-black">
                  Konfirmasi Kata Sandi Baru
                </div>
                <v-text-field
                  v-model="confirm_password"
                  outlined
                  :rules="cnfrmPswRules"
                  required
                  :type="show3 ? 'text' : 'password'"
                  :append-icon="show3 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show3 = !show3"
                  hide-details="auto"
                ></v-text-field>
                <!-- :type="show3 ? 'text' : 'password'"
                  append-icon="show3 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show3 = !show3" -->
              </v-col>
            </v-row>
            <!-- v-if="akun.fullname === null || akun.fullname === ''" -->
            <v-btn
              class="mt-3"
              color="primary"
              dark
              :disabled="confirm_password === ''"
              :loading="submitting"
              @click="updatePassword()"
            >
              Simpan
            </v-btn>
          </div>
        </v-tab-item>

        <v-tab-item class="mt-8">
          <v-card
            v-if="form.custom_domain === null || form.custom_domain === ''"
            class="mx-auto pa-4 mb-5"
            elevation="2"
            style="border-radius: 8px; border: 1px solid #e2e8f0"
          >
            <v-card-title
              v-if="form.domain === null || form.domain === ''"
              class="text-black pt-0 px-0 text-h5 font-weight-bold"
            >
              Atur dan Aktifkan Subdomain Anda
            </v-card-title>
            <v-card-title
              v-else
              class="text-black pt-0 px-0 text-h5 font-weight-bold"
            >
              Subdomain Anda
            </v-card-title>
            <p
              v-if="form.domain != null || form.domain === ''"
              class="text-subtitle-1 text-muted"
            >
              Subdomain Anda telah aktif dan dapat mengakses Momofin GO melalu
              alamat subdomain Anda di bawah ini
            </p>
            <v-alert v-else dense outlined type="warning" text>
              Pengaturan subdomain hanya bisa dilakukan satu kali. Pastikan
              bahwa nama subdomain sudah sesuai sebelum melanjutkan.
            </v-alert>
            <br />
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <!-- <div class="text-body-2 mb-2 text-black">Subdomain</div>                 -->
                <v-text-field
                  v-if="form.domain === null || form.domain === ''"
                  v-model="domain"
                  :suffix="subdomain"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-else
                  v-model="form.domain"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-btn
                  class="ma-2"
                  outlined
                  color="primary"
                  small
                  @click="copyToClipBoard(form.domain)"
                >
                  <v-icon>ri-file-copy-line</v-icon>
                </v-btn>
                <a
                  class="ma-2 v-btn v-btn--outlined theme--light elevation-0 v-size--small primary--text"
                  :href="'https://' + form.domain"
                  target="_blank"
                >
                  <v-icon style="color: #0068d6">ri-share-box-fill</v-icon>
                </a>
              </v-col>
            </v-row>
            <v-btn
              v-if="
                (form.domain === null || form.domain === '') &&
                roles.name != 'admin'
              "
              class="mt-3"
              color="primary"
              dark
              :disabled="domain == null"
              :loading="submitting"
              @click="createDomain()"
            >
              Aktivasi
            </v-btn>
          </v-card>
          <v-card
            v-if="
              (form.domain != null || form.domain != '') &&
              roles.name != 'admin'
            "
            class="mx-auto pa-4 mb-5"
            elevation="2"
            style="border-radius: 8px; border: 1px solid #e2e8f0"
          >
            <v-card-title class="text-black pt-0 px-0 text-h5 font-weight-bold">
              Custom Domain
              <span
                class="primary--text text-body-2"
                @click="alertCustom = true"
                style="cursor: pointer"
                ><v-icon color="primary" class="ml-2"
                  >ri-information-line</v-icon
                >
                Apa itu custom domain?</span
              >
            </v-card-title>
            <p
              v-if="form.custom_domain === '' || form.custom_domain === null"
              class="text-subtitle-1 text-muted"
            >
              Silahkan masukan domain Anda untuk diaktivasi
            </p>
            <p v-else class="text-subtitle-1 text-muted">
              Domain Anda telah diaktivasi Anda dapat mengakses Momofin GO
              melalu alamat subdomain Anda di bawah ini
            </p>
            <v-alert
              v-model="alertCustom"
              close-text="Close Alert"
              close-icon="ri-close-circle-line"
              dense
              text
              color="warning"
              dismissible
              class="mt-3"
            >
              Anda dapat mengatur subdomain Momofin GO menggunakan hosting milik
              sendiri.
            </v-alert>
            <br />
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <div class="text-body-1 mb-2 text-black">Domain Anda</div>
                <v-text-field
                  v-if="
                    form.custom_domain === null || form.custom_domain === ''
                  "
                  v-model="custom_domain"
                  placeholder="company.example.com"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-else
                  v-model="form.custom_domain"
                  readonly
                  outlined
                ></v-text-field>
                <!-- suffix=".momofingo.com" -->
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <a
                  v-if="form.custom_domain != ''"
                  class="ml-3 mt-8 v-btn v-btn--outlined theme--light elevation-0 v-size--small primary--text"
                  :href="'https://' + form.custom_domain"
                  target="_blank"
                >
                  <v-icon style="color: #0068d6">ri-share-box-fill</v-icon>
                </a>
              </v-col>
            </v-row>
            <v-btn
              v-if="form.custom_domain === null || form.custom_domain === ''"
              class="mt-3"
              color="primary"
              dark
              :disabled="custom_domain === null || custom_domain === ''"
              @click="dialog_confirm = true"
            >
              Aktivasi
            </v-btn>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
    <v-dialog v-model="dialog_confirm" persistent width="500">
      <v-card>
        <v-card-title class="text-h5"> Aktivasi Custom Domain </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="text-subtitle-1 pa-4">
          Dengan mengaktifkan penggunaan custom domain, maka layanan Momofin GO
          Anda secara otomatis akan diarahkan ke alamat domain yang telah Anda
          masukkan.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="dialog_confirm = false">
            Batalkan
          </v-btn>
          <v-btn color="primary" dark :loading="submitting" @click="activate()">
            Lanjutkan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_success" persistent width="600">
      <v-card class="text-center">
        <v-card-text class="col-md-10 mx-auto pa-4">
          <img src="@/assets/images/tick-circle.svg" width="60" />
          <p class="text-center text-h5">Selamat Subdomain Anda Sudah Aktif</p>
          <div class="mb-0 d-flex col-md-10 mx-auto">
            <v-text-field
              v-model="custom_domain"
              readonly
              class="rounded"
            ></v-text-field>
            <v-btn
              class="ma-2"
              outlined
              color="primary"
              small
              @click="copyToClipBoard(custom_domain)"
            >
              <v-icon>ri-file-copy-line</v-icon>
            </v-btn>
            <a
              class="ma-2 v-btn v-btn--outlined theme--light elevation-0 v-size--small primary--text"
              :href="'https://' + custom_domain"
              target="_blank"
            >
              <v-icon style="color: #0068d6">ri-share-box-fill</v-icon>
            </a>
          </div>

          <p class="mb-3">
            Domain Anda telah diaktivasi Anda dapat mengakses Momofin GO melalu
            alamat subdomain Anda di bawah ini
          </p>
          <v-btn color="primary" dark @click="dialog_success = false">
            Oke
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="notification.state" top color="primary" outlined>
      {{ notification.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          icon
          v-bind="attrs"
          @click="notification.state = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="notificationError.state" top color="error" outlined>
      {{ notificationError.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="error"
          icon
          v-bind="attrs"
          @click="notificationError.state = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
// import axios from "axios";
import validationMixin from "../_mixins/validation";

export default {
  mixins: [validationMixin],

  data() {
    return {
      locationDisabled: false,
      notification: {
        state: false,
        message: "",
      },
      notificationError: {
        state: false,
        message: "",
      },
      test: "unverified",
      form: {},
      submitting: false,
      email: null,
      nib: "",
      logo: "",
      nik: "",
      npwp: "",
      alert_error: false,
      alert_success: false,
      alertCustom: false,
      message: "",
      Alert: false,
      dialog_confirm: false,
      dialog_success: false,
      domain: "",
      custom_domain: "",
      akun: {},
      fullname: "",
      nameRules: [
        (v) => !!v || "Subdomain Tidak Boleh Kosong",
        // v => /^[A-Za-z ]+$/.test(v) || 'Tidak boleh ada simbol',
      ],

      show: false,
      show2: false,
      show3: false,
      current_password: "",
      password_new: "",
      confirm_password: "",
      crpswRules: [(v) => !!v || "Password saat tidak boleh kosong"],
      pswRules: [(v) => !!v || "Password baru tidak boleh kosong"],
      nikRules: [
        (v) => !!v || "NIK Tidak Boleh Kosong",
        (v) => (v.length != 16 ? "NIK harus 16 digit" : null),
      ],
      nibRules: [
        (v) => !!v || "NIB Tidak Boleh Kosong",
        (v) => (v.length != 13 ? "NIB harus 13 digit" : null),
      ],
      npwpRules: [
        (v) => !!v || "NPWP Tidak Boleh Kosong",
        (v) => (v.length > 17 ? "NPWP maksimal 16 digit" : null),
        (v) => (v.length < 15 ? "NPWP minimal 15 digit" : null),
      ],
      valid: true,
      errors: null,
      roles: {},

      province_id: null,
      city_id: null,
      provinces: [],
      cities: [],
      locationLoading: false,
    };
  },
  mounted() {
    this.getReport();
    this.getDomain();
    this.getProfile();
    this.getProvinces();
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
    cnfrmPswRules() {
      return [
        () =>
          this.password_new === this.confirm_password ||
          "Konfirmasi kata sandi tidak sama",
        (v) => !!v || "Konfirmasi kata sandi tidak boleh kosong",
      ];
    },
    subdomain() {
      return process.env.VUE_APP_MAIN_DOMAIN;
    },
  },
  methods: {
    async saveLocationData() {
      this.locationLoading = true;
      await this.axios
        .post("/auth/dashboard/update_location", {
          province_id: this.province_id,
          city_id: this.city_id,
        })
        .then(() => {
          this.notification.state = true;
          this.notification.message = "Data lokasi berhasil disimpan";
          this.getDomain();
        })
        .catch((e) => {
          let message = e.response ? e.response.data.success : e.msg;
          this.notificationError.state = true;
          this.notificationError.message = message;
          this.getDomain();
        });
      this.locationLoading = false;
    },
    getBlobUrl(file) {
      function convertBase64ToBlob(base64Image) {
        // Split into two parts
        const parts = base64Image.split(";base64,");

        // Hold the content type
        const imageType = parts[0].split(":")[1];

        // Decode Base64 string
        const decodedData = window.atob(parts[1]);

        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);

        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
          uInt8Array[i] = decodedData.charCodeAt(i);
        }

        // Return BLOB image after conversion
        return new Blob([uInt8Array], { type: imageType });
      }
      return URL.createObjectURL(convertBase64ToBlob(file));
    },
    async getProvinces() {
      const {
        data: { data: provinces },
      } = await this.axios.get("econtract/master/province");

      this.provinces = provinces;
    },
    async getCities(province_id) {
      this.city_id = null;
      const {
        data: { data: cities },
      } = await this.axios.get(
        "econtract/master/city/?province_id=" + province_id
      );
      this.cities = cities;
    },
    copyToClipBoard(textToCopy) {
      navigator.clipboard.writeText(textToCopy);
      this.notification.state = true;
      this.notification.message = "Copied to clipboard";
    },
    onFileSelectLogo(e) {
      const file = e.target.files[0];
      const fileSize = file.size;
      const mb = fileSize / Math.pow(1024, 2);
      if (mb >= 5) {
        this.$refs.FileInputLogo.value = "";
        this.notificationError.state = true;
        this.notificationError.message = "Upload file ukuran maksimal 5 Mb";
        return;
      }

      this.logo = file; // this method will be responsible for uploading file to server. Move all code written in onFileSelect to this method.
      // console.log("came here",this.logo);
    },
    onFileSelectNIK(e) {
      // console.log("came here");
      const file = e.target.files[0];

      const fileSize = file.size;
      const mb = fileSize / Math.pow(1024, 2);
      if (mb >= 5) {
        this.$refs.FileInputNIK.value = "";
        this.notificationError.state = true;
        this.notificationError.message = "Upload file ukuran maksimal 5 Mb";
        return;
      }

      this.nik = file; // this method will be responsible for uploading file to server. Move all code written in onFileSelect to this method.
    },
    onFileSelectNIB(e) {
      // console.log("came here");
      const file = e.target.files[0];

      const fileSize = file.size;
      const mb = fileSize / Math.pow(1024, 2);
      if (mb >= 5) {
        this.$refs.FileInputNIB.value = "";
        this.notificationError.state = true;
        this.notificationError.message = "Upload file ukuran maksimal 5 Mb";
        return;
      }

      this.nib = file; // this method will be responsible for uploading file to server. Move all code written in onFileSelect to this method.
    },
    onFileSelectNPWP(e) {
      // console.log("came here");
      const file = e.target.files[0];

      const fileSize = file.size;
      const mb = fileSize / Math.pow(1024, 2);
      if (mb >= 5) {
        this.$refs.FileInputNPWP.value = "";
        this.notificationError.state = true;
        this.notificationError.message = "Upload file ukuran maksimal 5 Mb";
        return;
      }

      this.npwp = file; // this method will be responsible for uploading file to server. Move all code written in onFileSelect to this method.
    },
    getReport() {
      this.axios
        .get(`/econtract/inventory/client/home/summary_token`)
        .then((res) => {
          this.report = res.data.data.esgn;
          this.emet = res.data.data.emet;
        });
    },
    getDomain() {
      this.axios.get(`/auth/dashboard/profile`).then((res) => {
        const data = res.data.data;
        this.form = res.data.data;
        this.form.address = [
          data.address,
          data?.city?.name,
          data?.province?.name,
        ]
          .filter(Boolean)
          .join(",");
        this.fullname = this.form.name;
        const profile = this.form;
        this.province_id = profile.province?.id;
        if (this.province_id) {
          this.locationDisabled = true;
          this.getCities(this.province_id);
        }
        this.city_id = profile.city?.id;
      });
    },
    getProfile() {
      this.axios.get(`/auth/users/profile`).then((res) => {
        // console.log(res)
        this.akun = res.data.data;
        this.roles = this.akun.roles;
      });
    },
    async uploadLogo() {
      this.submitting = true;
      // console.log('logo',this.logo)
      if (this.logo != "") {
        var formData = new FormData();
        let a = this.logo;

        formData.append("image", a);
        formData.append("type", "logo");

        return await this.axios.post(`/auth/dashboard/upload_image`, formData);
      } else {
        this.submitting = false;
        this.notification.state = true;
        this.notification.message = "File Logo Perusahaan tidak boleh kosong";
      }
    },
    async uploadNik() {
      this.submitting = true;
      if (this.nik != "") {
        var formData = new FormData();
        let a = this.nik;

        formData.append("image", a);
        formData.append("type", "ktp_image");

        return await this.axios.post(`/auth/dashboard/upload_image`, formData);
      } else {
        this.submitting = false;
        this.notification.state = true;
        this.notification.message = "File NIK tidak boleh kosong";
      }
    },
    async uploadNib() {
      this.submitting = true;
      if (this.nib != "") {
        var formData = new FormData();
        let a = this.nib;

        formData.append("image", a);
        formData.append("type", "nib_image");

        return await this.axios.post(`/auth/dashboard/upload_image`, formData);
      } else {
        this.submitting = false;
        this.notification.state = true;
        this.notification.message = "File NIB tidak boleh kosong";
      }
    },
    async uploadNpwp() {
      this.submitting = true;
      if (this.npwp != "") {
        var formData = new FormData();
        let a = this.npwp;

        formData.append("image", a);
        formData.append("type", "npwp_image");

        return await this.axios.post(`/auth/dashboard/upload_image`, formData);
      } else {
        this.submitting = false;
        this.notification.state = true;
        this.notification.message = "File NPWP tidak boleh kosong";
      }
    },
    async updateProfile() {
      this.submitting = true;
      // setTimeout(() => {
      if (
        (this.$refs.form.validate() && this.logo != "") ||
        (this.form.logo != "" && this.nik != "") ||
        (this.form.ktp_image != "" && this.nib != "") ||
        (this.form.nib_image != "" && this.npwp != "") ||
        this.form.npwp_image != ""
      ) {
        await this.uploadLogo();
        await this.uploadNik();
        await this.uploadNib();
        await this.uploadNpwp();
        await this.updateCompany();
      } else {
        this.submitting = false;
        this.notificationError.state = true;
        this.notificationError.message =
          "Silahkan cek kembali data perusahaan dan unggah dokumen wajib diisi";
        window.scrollTo(0, 0);
      }
      // }, 50);
    },
    async updateCompany() {
      this.submitting = true;
      const params = {
        ktp: this.form.ktp,
        npwp: this.form.npwp,
        company_name: this.form.company_name,
        address: this.form.address,
        nib: this.form.nib,
        // custom_domain:this.form.custom_domain
      };

      this.axios
        .post(`/auth/dashboard/profile`, params)
        .then((res) => {
          this.submitting = false;
          if (res.data.code === 200) {
            let message = res ? res.data.msg : res.msg;
            this.notification.state = true;
            this.notification.message = message;
            this.getDomain();
            setTimeout(() => {
              this.notification.state = false;
              this.notification.message = null;
            }, 4000);
          } else {
            let message = res ? res.data.msg : res.msg;
            this.notificationError.state = true;
            this.notificationError.message = message;
          }
        })
        .catch((error) => {
          this.submitting = false;
          let message = error.response ? error.response.data.msg : error.msg;
          this.notification.state = true;
          this.notification.message = message;
        });
    },
    async activate() {
      this.submitting = true;
      const params = {
        custom_domain: this.custom_domain,
      };

      this.axios
        .post(`/auth/dashboard/update_custom_domain`, params)
        .then((res) => {
          // console.log(res)

          this.submitting = false;
          // if(res.data.code === 200){
          this.dialog_confirm = false;
          this.dialog_success = true;
          let message = res ? res.data.msg : res.msg;
          this.notification.state = true;
          this.notification.message = message;
          this.getDomain();
          setTimeout(() => {
            this.notification.state = false;
            this.notification.message = null;
          }, 4000);
          // }else{
          //   let message = res ? res.data.msg : res.msg;
          //   this.notification.state = true;
          //   this.notification.message =  message
          // }
        })
        .catch((error) => {
          // console.log(error.response)
          this.submitting = false;
          this.dialog_confirm = false;
          let message = error.response ? error.response.data.msg : error.msg;
          this.notification.state = true;
          this.notification.message = message;
        });
    },
    async saveFullname() {
      this.submitting = true;
      const params = {
        fullname: this.fullname,
      };

      await this.axios
        .post(`/auth/dashboard/update_fullname`, params)
        .then((res) => {
          // console.log(res)

          this.submitting = false;
          // if(res.data.code === 200){
          let message = res ? res.data.msg : res.msg;
          this.notification.state = true;
          this.notification.message = message;
          this.getProfile();
          setTimeout(() => {
            this.notification.state = false;
            this.notification.message = null;
          }, 4000);
          // }else{
          //   let message = res ? res.data.msg : res.msg;
          //   this.notification.state = true;
          //   this.notification.message =  message
          // }
        })
        .catch((error) => {
          // console.log(error.response)
          this.submitting = false;
          this.dialog_confirm = false;
          let message = error.response ? error.response.data.msg : error.msg;
          this.notification.state = true;
          this.notification.message = message;
        });
      this.$root.$emit("updateName", this.fullname);
    },
    async updatePassword() {
      this.submitting = true;
      const params = {
        old_password: this.current_password,
        new_password: this.password_new,
        new_password_confirm: this.confirm_password,
      };

      this.axios
        .post(`/auth/dashboard/update_password`, params)
        .then((res) => {
          // console.log(res)

          this.submitting = false;
          // if(res.data.code === 200){
          let message = res ? res.data.msg : res.msg;
          this.notification.state = true;
          this.notification.message = message;
          setTimeout(() => {
            this.notification.state = false;
            this.notification.message = null;
          }, 4000);
          // }else{
          //   let message = res ? res.data.msg : res.msg;
          //   this.notification.state = true;
          //   this.notification.message =  message
          // }
        })
        .catch((error) => {
          // console.log(error.response)
          this.submitting = false;
          let message = error.response ? error.response.data.msg : error.msg;
          this.notification.state = true;
          this.notification.message = message;
        });
    },
    async createDomain() {
      this.submitting = true;
      const params = {
        domain: "https://" + this.domain + "." + this.subdomain,
      };

      this.axios
        .post(`/auth/dashboard/domain`, params)
        .then((res) => {
          // console.log(res)

          this.submitting = false;
          // if(res.data.code === 200){
          let message = res ? res.data.msg : res.msg;
          this.notification.state = true;
          this.notification.message = message;
          this.getDomain();
          setTimeout(() => {
            this.notification.state = false;
            this.notification.message = null;
          }, 4000);
          // }else{
          //   let message = res ? res.data.msg : res.msg;
          //   this.notification.state = true;
          //   this.notification.message =  message
          // }
        })
        .catch((error) => {
          // console.log(error.response)
          this.submitting = false;
          this.dialog_confirm = false;
          let message = error.response ? error.response.data.msg : error.msg;
          this.notification.state = true;
          this.notification.message = message;
        });
    },
  },
};
</script>

<style scoped lang="scss">
#btn-disable .v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
p {
  margin: 0;
  padding: 0;
}
.pricing-wrapper {
  padding: 0 10px;
  margin: 40px 0;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  @media (min-width: 768px) {
    gap: 43px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.profile-img {
  border-radius: 8px;
}
.pricing {
  &-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    &__tabs {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 400px;
      li {
        font-size: small;
      }
    }
  }
  &-card {
    border-radius: 12px;
    padding: 20px;
    border: solid 1px #e0e0e0 !important;
    &-header {
      display: flex;
      place-items: center;
      padding: 0 0 20px 0;
      // padding: 20px 0;
      padding-top: 10;
      &__img {
        margin-right: 0.5rem;
      }
      &__title {
        font-size: 1rem;
      }
      &__price {
        font-size: 0.5rem;
        font-weight: bold;
      }
    }
    &-feature {
      &-list {
        border-top: solid #e0e0e0 1px;
        list-style: none;
        min-height: 100px;
        padding: 20px 0 !important;
      }
      &-item {
        display: flex;
        place-items: center;
        padding: 5px;
        &__title {
          margin: 0;
          margin-bottom: 0 !important;
        }
        &__icon {
          margin-right: 5px;
          width: 20px;
          color: #9ad4fb;
        }
      }
    }
    &-counter-price {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
    }
    &-action {
      padding: 20px 0;
      &__button {
        height: 44px !important;
        border-radius: 6px;
      }
    }
  }
}
</style>
