<template>
  <v-card rounded="lg" class="transparent">
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">
      Riwayat Pemakaian
    </v-card-title>
    <section class="content">
      <v-card class="px-5">
        <div class="content-header row">
          <div class="col-md-6 row">
            <div class="col-lg-6 col-md-12">
              <v-menu v-model="menu" :close-on-content-click="false" offset-y max-width="320">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    solo
                    flat
                    clearable
                    readonly
                    placeholder="Pilih Rentang Tanggal"
                    v-bind="attrs"
                    v-on="on"
                    v-model="dateRangeText"
                  >
                    <template #append>
                      <v-icon v-bind="attrs" v-on="on">ri-calendar-line</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  @change="handleFilterDate"
                  range
                  width="100%"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="col-lg-6 col-md-12">
              <v-text-field
                outlined
                append-icon="$search"
                class=""
                placeholder="Cari No. Invoice/Pengguna"
                solo
                v-model="params.search"
                flat
                @input="handleFilterQuery"
              ></v-text-field>
            </div>
          </div>
          <div class="col-md-6 content-header__right text-right">
            <div class="row">
              <div class=" col-md-12">
                <v-btn class="mr-2" outlined color="primary" @click="handleResetFilter"
                  >Reset</v-btn
                >
                <v-btn
                  color="grey darken-4"
                  class="white--text"
                  @click="handleExportData()"
                  :loading="isExportLoading"
                  style="min-height: 39px;"
                >
                  <v-icon class="mr-2">ri-download-cloud-fill </v-icon> Export
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <v-data-table
          class="mt-5"
          :headers="headers"
          :items="items || []"
          :loading="isLoading"
          :page="params.page"
          :server-items-length="pageData.total_data"
          :items-per-page="params.limit"
          @update:page="handlePageChanged($event)"
          @update:items-per-page="handleLimitChanged($event)"
        >
          <!-- <template v-slot:[`item.items`]="{item}">
            <div
              v-for="(product, id) in item.items"
              :key="id"
              class="py-3 d-flex"
            >
              <div class="mr-3 d-flex justify-center align-center">
                <img
                  style="width: 70%;"
                  src="@/assets/images/materai-icon.png"
                  alt=""
                />
              </div>
              <div>
                <h4>{{ product.title }}</h4>
                <p>{{ product.description }}</p>
              </div>
            </div>
          </template>
          <template v-slot:[`item.amount`]="{item}">
            <div
              v-for="(product, id) in item.items"
              :key="id"
              class="py-3 d-flex"
            >
              <div>
                <p>Rp. {{  formatToPrice(product.price) }}</p>
              </div>
            </div>
          </template> -->
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatToDate(item.createdAt) }}
          </template>
        </v-data-table>
      </v-card>
      <v-snackbar top v-model="snackbar.state" color="primary" outlined>
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </section>
  </v-card>
</template>

<script>
import { onMounted, computed, ref } from '@vue/composition-api';
import axios from 'axios';
import { formatPrice } from '../../../lib/formatDate';

// const states = {
//   dates: [],
//   statuses: [],
//   status: [],
//   search: '',
//   menu: '',
//   balance: 0,
//   totalPoints: [],
//   datas: [],
//   limit: 10,
//   page: 1,
//   isLoading: false,
// };

// const statuses = ["Pending", "Completed"];

export default {
  setup() {
    const dateRangeText = computed(() => {
      if (dates.value) return dates.value.join(' ~ ');
      return '';
    });

    const snackbar = ref({});
    const params = ref({});
    const isLoading = ref(false);
    const isExportLoading = ref(false);
    const pageData = ref({});
    const tableData = ref();
    const dates = ref();
    const menu = ref();

    const headers = computed(() => [
      {
        text: 'TANGGAL',
        align: 'start',
        sortable: false,
        value: 'createdAt',
      },
      { text: 'NO. INVOICE', value: 'id' },
      { text: 'CUSTOMER', value: 'customer' },
      { text: 'ID DOKUMEN', value: 'document_id' },
      { text: 'EMET', value: 'emet_value' },
      { text: 'ESGN', value: 'esgn_value' },
    ]);

    const items = computed(() => (tableData.value ? tableData.value.selling : []));

    function formatToPrice(value) {
      return formatPrice(value);
    }

    function formatToDate(value) {
      return value;
    }

    async function getData() {
      isLoading.value = true;
      let { data: response } = await axios
        .get('/econtract/web_app/history_usage', {
          params: params.value,
        })
        .finally(() => {
          isLoading.value = false;
        });
      if (response) {
        tableData.value = response.data;
        pageData.value = tableData.value.pagination;
      }
    }

    function handleLimitChanged(value) {
      if (value == -1) params.value.limit = pageData.value.total_data;
      else params.value.limit = value;
      getData();
    }

    function handleFilterDate(__dates) {
      if (Array.isArray(__dates) && __dates.length == 2) {
        if (__dates[0] > __dates[1]) __dates = [__dates[1], __dates[0]];
        dates.value = __dates;
        params.value.date = __dates.join(',');
      } else {
        params.value.date = undefined;
        dates.value = undefined;
      }
      getData();
    }

    const searchTimeout = ref();
    function handleFilterQuery() {
      clearTimeout(searchTimeout.value);
      searchTimeout.value = undefined;
      searchTimeout.value = setTimeout(() => {
        getData();
      }, 3e2);
    }

    function handlePageChanged(value) {
      params.value.page = value;
      getData();
    }

    function handleResetFilter() {
      dates.value = undefined;
      params.value = {
        page: params.value.page,
        limit: params.value.limit,
      };
      getData();
    }

    function handleExportData() {
      isExportLoading.value = true;
      this.axios
        .get(`/econtract/inventory/client/selling/export`, { params: { date: params.value.date } })
        .then((res) => {
          this.snackbar.text = 'Successfully export data';
          this.snackbar.state = true;

          const anchor = document.createElement('a');
          anchor.href = res.data.path;
          anchor.target = '_blank';
          anchor.download = anchor.href.split('/').pop();
          anchor.click();
          document.removeChild(anchor);
        })
        .finally(() => {
          isExportLoading.value = false;
        });
    }

    onMounted(async () => {
      params.value.page = 1;
      params.value.limit = 10;
      getData();
    });

    return {
      // var
      dates,
      isExportLoading,
      isLoading,
      items,
      menu,
      params,
      snackbar,
      tableData,
      pageData,
      // computed
      dateRangeText,
      headers,
      // method
      formatToPrice,
      formatToDate,
      getData,
      handleLimitChanged,
      handlePageChanged,
      handleFilterQuery,
      handleFilterDate,
      handleResetFilter,
      handleExportData,
    };
  },
};
</script>

<style lang="scss">
.card-header {
  &__wrapper {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  &__item {
    padding: 31px 20px;
    @media (min-width: 768px) {
      padding: 31px 40px;
    }
    box-shadow: inset 0px -1px 0px #e4e4e7;
    background: rgb(255, 255, 255);
    &-group {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-column: span 3 / span 3;
      padding: 31px 61px;
      background: rgb(255, 255, 255);
      box-shadow: inset 0px -1px 0px #e4e4e7;
    }
  }
  &__card {
    &-title {
      color: #9e9e9e;
      font-weight: 600;
    }
    &-subtitle {
      font-size: 18px;
      span {
        font-size: 13px;
      }
      &-h2 {
        font-size: 1.5rem;
        span {
          font-size: 13px;
        }
      }
    }
  }
}
.content {
  margin-top: 35px;
  padding-top: 20px;
  background: #ffffff;
  &-header {
    display: flex;
    justify-content: space-between;
    &__right {
      display: flex;
      align-items: center;
    }
  }
}
.capitalize {
  text-transform: capitalize;
}
.card-header__item-group {
  box-shadow: unset !important;
}

@media (max-width: 768px) {
  .card-header__item-group {
    grid-template-columns: auto !important;
    padding: 31px 20px !important;
  }
  .v-application--is-ltr .v-data-footer__select {
    margin: 0px;
  }
  .v-application--is-ltr .v-data-footer__select .v-select {
    margin-left: 5px;
    margin-right: 0px;
  }
  .v-application--is-ltr .v-data-footer__pagination {
    margin-right: 5px;
    margin-left: 5px;
  }
  .v-application--is-ltr .v-data-footer__icons-after .v-btn:first-child {
    margin-left: 0px;
  }
}
</style>
