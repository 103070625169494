var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"transparent",attrs:{"rounded":"lg"}},[_c('v-card-title',{staticClass:"text-black pt-0 px-0 fs-20 font-weight-bold"},[_vm._v(" Riwayat Pembelian ")]),_c('section',{staticClass:"content"},[_c('v-card',{staticClass:"px-5"},[_c('div',{staticClass:"content-header row"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-md-12"},[_c('v-select',{attrs:{"items":_vm.availableFilter.status || [],"item-value":"value","solo":"","flat":"","item-text":"label","placeholder":"Pilih Status","outlined":""},on:{"change":_vm.handleStatusChanged},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('div',{staticClass:"col-lg-3 col-md-12"},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","append-icon":"ri-calendar-line","solo":"","flat":"","clearable":"","readonly":"","hide-details":"auto","placeholder":"Pilih Rentang Tanggal"},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isDatePickerShow),callback:function ($$v) {_vm.isDatePickerShow=$$v},expression:"isDatePickerShow"}},[_c('v-date-picker',{staticStyle:{"width":"100%"},attrs:{"range":""},on:{"change":_vm.handleDateChanged},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1),_c('div',{staticClass:"col-lg-3 col-md-12"},[_c('v-text-field',{attrs:{"outlined":"","append-icon":"$search","placeholder":"Cari No. Invoice","solo":"","flat":"","hide-details":"auto"},on:{"keyup":_vm.handleSearchChanged},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"col-lg-3 col-md-12 text-right"},[_c('v-btn',{staticClass:"mr-2",staticStyle:{"min-height":"39px"},attrs:{"color":"primary","outlined":""},on:{"click":_vm.handleClickResetFilter}},[_vm._v(" Reset ")]),_c('v-btn',{staticClass:"white--text",staticStyle:{"min-height":"39px"},attrs:{"color":"grey darken-4","loading":_vm.submitting},on:{"click":function($event){return _vm.getExport()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("ri-download-cloud-fill ")]),_vm._v(" Export ")],1)],1)])]),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.rowData,"loading":_vm.isLoading,"items-per-page":_vm.limit,"page":_vm.page,"server-items-length":_vm.paginationData.total_data || 0,"loading-text":"Loading... Please wait"},on:{"update:items-per-page":_vm.handleLimitChange,"update:page":_vm.handlePageChanged},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"capitalize my-3",attrs:{"color":_vm.getStatusColor(item.status),"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-3 d-flex"},[_c('div',{staticClass:"mr-3 d-flex justify-center align-center"},[(item.product == 'e-Meterai')?_c('img',{staticStyle:{"width":"56px"},attrs:{"src":require("@/assets/images/emet.png"),"alt":""}}):_c('img',{staticStyle:{"width":"56px"},attrs:{"src":require("@/assets/images/e-sign.png"),"alt":""}})]),_c('div',[(item.product === 'e-Meterai')?_c('h4',[_vm._v("EMET")]):_c('h4',[_vm._v("ESGN")]),_c('div',{staticStyle:{"border-top":"0px"}},_vm._l((_vm.getJson(
                    item.productDescription
                  )),function(facilities,index){return _c('div',{key:'A' + index},[_vm._v(" "+_vm._s(facilities)+" ")])}),0)])])]}},{key:"item.payment",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"capitalize"},[_vm._v(_vm._s(item.payment))])]}},{key:"item.amount",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_c('p',[_vm._v("Rp. "+_vm._s(_vm.formatToPrice(item.amount)))])])]}},{key:"item.invoiceId",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_c('a',{attrs:{"href":item.invoice_url,"target":"_blank"}},[_vm._v(_vm._s(item.invoiceId))])])]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('router-link',{staticClass:"text-underline",attrs:{"to":{ name: 'detail-purchase', params: { id: item.id } }}},[_vm._v("Invoice")])]}}],null,true)})],1),_c('v-snackbar',{attrs:{"top":"","color":_vm.snackbar.status || 'primary'},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){_vm.snackbar.state = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("$close")])],1)]}}]),model:{value:(_vm.snackbar.state),callback:function ($$v) {_vm.$set(_vm.snackbar, "state", $$v)},expression:"snackbar.state"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.snackbar.text)}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }