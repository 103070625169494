<template>
  <v-card rounded="lg" class="transparent">
    <br />
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">
      <div class="row">
        <div class="col-md-6 mx-auto text-center">
          <p
            class="text-h4 text-black pt-0 px-0 mb-0 pb-0"
            style="word-break: normal"
          >
            Invoice Pembelian Token
          </p>
        </div>
      </div>
    </v-card-title>
    <br />
    <p class="text-subtitle-1 px-3 d-flex d-sm-none">
      No. Invoice: {{ dataDetail.invoice_code }}
    </p>
    <v-card class="pa-2 ma-3" elevation="2">
      <v-card-text class="pb-2">
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <img src="@/assets/images/logo.png" class="w-100" width="150" />
            <p>
              PT. Mitra Era Teknologi <br />
              Go-Work Menara Rajawali Ground Floor - Jl. DR. Ide Anak Agung Gde
              Agung,<br />
              RT.5/RW.2, Kuningan, Kuningan Tim., Kecamatan Setiabudi, Jakarta,
              <br />Daerah Khusus Ibukota Jakarta 12950
            </p>
          </div>
          <div class="col-md-6 col-sm-6 d-xs-none">
            <p class="text-subtitle-1 text-right">
              No. Invoice: {{ dataDetail.invoice_code }}
            </p>
          </div>
          <div v-if="dataDetail.user" class="col-md-12 overflow-auto">
            <h1 class="text-h6 activate-complete__message-title mb-3">
              Dikeluarkan untuk
            </h1>
            <table
              class="text-subtitle-1"
              style="border: 0px solid; max-width: 100%"
            >
              <tr>
                <td class="pb-2 text-price-bold">Nama Pengguna</td>
                <td class="pb-2 text-price-bold">:</td>
                <td class="pb-2 text-price-bold">{{ dataDetail.user.name }}</td>
              </tr>
              <tr>
                <td class="pb-2 text-price-bold">Nama Perusahaan</td>
                <td class="pb-2 text-price-bold">:</td>
                <td class="pb-2 text-price-bold">
                  {{ dataDetail.user.companyName }}
                </td>
              </tr>
              <tr>
                <td class="pb-2 text-price-bold">Email</td>
                <td class="pb-2 text-price-bold">:</td>
                <td class="pb-2 text-price-bold">
                  {{ dataDetail.user.email }}
                </td>
              </tr>
              <tr>
                <td class="pb-2 text-price-bold">No. Telepon</td>
                <td class="pb-2 text-price-bold">:</td>
                <td class="pb-2 text-price-bold">
                  {{ dataDetail.user.phone }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="pa-2 ma-3" elevation="2">
      <v-card-text class="pb-2">
        <div class="row">
          <div class="col-md-12 overflow-auto">
            <h1 class="text-h6 activate-complete__message-title mb-3">
              Detail Pembelian
            </h1>
            <table
              class="text-subtitle-1"
              style="width: 100%; border: 0px solid; border-collapse: collapse"
            >
              <tr>
                <td class="pb-2 text-price-bold" style="width: 50%">
                  Tanggal & waktu pembelian
                </td>
                <td class="pb-2 text-right text-price-bold">
                  {{ formatToDate(dataDetail.order_datetime) }}
                </td>
              </tr>
              <tr>
                <td class="pb-2 text-price-bold" style="width: 50%">Produk</td>
                <td class="pb-2"></td>
              </tr>
              <tr style="background: #f1f5f9">
                <td
                  class="pa-4 ma-0 pb-5"
                  style="
                    border-bottom-left-radius: 6.8px;
                    border-top-left-radius: 6.8px;
                    width: 50%;
                  "
                >
                  <div
                    v-if="dataDetail.product_name === 'e-Meterai'"
                    class="d-flex"
                  >
                    <img
                      style="width: 60px; object-fit: contain"
                      src="@/assets/images/inventory.png"
                      class="mr-3"
                    />
                    <span class="text-h5 font-weight-bold text-price-bold"
                      >EMET <br />
                      <span class="text-body-1 text-muted text-price-bold"
                        >E-Meterai <br
                      /></span>
                      <span class="text-body-2 text-muted text-price-bold"
                        >Meterai Elektronik</span
                      >
                    </span>
                  </div>
                  <div v-else class="d-flex">
                    <img
                      class="mr-3 e-image"
                      src="@/assets/images/e-sign.png"
                    />
                    <span style="display: block">
                      <span class="font-weight-bold text-price-bold"
                        >ESIGN</span
                      >
                      <br />
                      <span
                        class="text-muted text-price-bold text-normal"
                        style="white-space: nowrap"
                        >E-Signature</span
                      >
                    </span>
                  </div>
                </td>
                <td
                  class="pa-4 ma-0 pb-5 text-right font-weight-bold text-price-bold"
                  style="
                    border-bottom-right-radius: 6.8px;
                    border-top-right-radius: 6.8px;
                  "
                >
                  @ {{ formatIdr(dataDetail.price) }}
                </td>
              </tr>
              <tr>
                <td class="pa-2 text-price-bold" style="width: 50%">
                  <span v-if="dataDetail.product_name === 'e-Meterai'"
                    >EMET</span
                  >
                  <span v-if="dataDetail.product_name === 'e-signature'"
                    >ESGN</span
                  >
                </td>
                <td class="pa-2 text-right text-price-bold">
                  {{ dataDetail.qty }} x {{ formatIdr(dataDetail.price) }}
                </td>
              </tr>
              <tr>
                <td class="pa-2 text-price-bold" style="width: 50%">
                  Biaya Platform
                </td>
                <td class="pa-2 text-right text-price-bold">
                  {{ dataDetail.qty }} x
                  {{ formatIdr(dataDetail.platform_fee) }}
                </td>
              </tr>
              <tr>
                <td>
                  <v-divider></v-divider>
                </td>
                <td>
                  <v-divider></v-divider>
                </td>
              </tr>
              <tr>
                <td class="pa-2" style="width: 50%">
                  <p class="text-total-bold">Total</p>
                </td>
                <td class="pa-2 text-right">
                  <p class="text-total-bold">
                    {{ formatIdr(dataDetail.total_invoice) }}
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="pa-2 ma-3" elevation="2">
      <v-card-text class="">
        <div class="row">
          <div class="col-md-12">
            <h1 class="text-h6 activate-complete__message-title mb-3">
              Detail Pembayaran
            </h1>
            <table
              class="text-subtitle-1"
              style="width: 100%; border: 0px solid"
            >
              <tr>
                <td class="pb-2 text-price-bold">Metode Pembayaran</td>
                <td class="pb-2"></td>
                <td class="pb-2 text-right text-price-bold">
                  {{ dataDetail.payment_channel }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="pa-2 ma-3" elevation="2">
      <v-card-text class="pb-2">
        <div class="row">
          <div class="col-md-12">
            <!-- <h1 class="text-h6 activate-complete__message-title mb-2">
              Status
            </h1> -->
            <table
              class="text-subtitle-1 mb-2"
              style="width: 100%; border: 0px solid"
            >
              <tr>
                <td class="text-h6">Status</td>
                <td class=""></td>
                <td class="text-right font-weight-bold">
                  {{ dataDetail.status }}
                </td>
              </tr>
            </table>

            <a
              v-if="dataDetail.status === 'PENDING'"
              :href="dataDetail.invoice_url"
              target="_blank"
              class="v-btn v-btn--block v-btn--outlined v-btn--router theme--light elevation-0 v-size--default primary--text"
            >
              Konfirmasi Pembayaran
            </a>
            <v-btn
              v-if="dataDetail.status === 'PAID'"
              color="primary"
              outlined
              block
              @click="download"
              :loading="submiting"
            >
              Unduh PDF
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar.state">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar.state = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from "axios";
import { formatDateTime } from "@/lib/formatDate";
import { formatPrice } from "@/lib/formatDate";

const formatToDate = (value) => {
  return formatDateTime(value);
};
const formatToPrice = (value) => {
  return formatPrice(value);
};
export default {
  setup() {
    return {
      formatToDate,
      formatToPrice,
    };
  },

  data: () => ({
    valid: true,
    isError: false,
    isLoading: false,
    submiting: false,
    showMessage: "",
    disabled: false,
    dataDetail: {},
    serverParams: {
      order_id: "",
      product_id: "",
      qty: "",
    },
    selectedItem: {},
    snackbar: {
      state: false,
      text: "",
    },
  }),

  mounted() {
    this.getListDetailPaket();
  },
  watch: {},
  computed: {},
  methods: {
    formatIdr(number) {
      try {
        return parseInt(number).toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
        });
      } catch (error) {
        return error.message;
      }
    },
    getListDetailPaket() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `econtract/inventory/client/purchase_v2/${this.$route.params.id}`
          )
          .then((response) => {
            const { data } = response.data;
            this.dataDetail = data;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    download() {
      this.submiting = true;
      this.axios
        .get(
          `econtract/inventory/client/purchase_v2/${this.$route.params.id}/export`
        )
        .then((res) => {
          this.submiting = false;
          // this.url_ = res.data.data.public_url;
          this.snackbar.text = res.data.message;
          this.snackbar.state = true;
          this.forceFileDownload(res.data.data.url, `Invoice ${this.dataDetail.invoice_code}`);
        })
        .catch((res) => {
          this.submiting = false;
          this.snackbar.text = res.data.message;
          this.snackbar.state = true;
        });
    },
    forceFileDownload(url, fileName) {
      // console.log(url)

     
      function convertBase64ToBlob(base64Image) {
        // Split into two parts
        const parts = base64Image.split(";base64,");

        // Hold the content type
        const imageType = parts[0].split(":")[1];

        // Decode Base64 string
        const decodedData = window.atob(parts[1]);

        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);

        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
          uInt8Array[i] = decodedData.charCodeAt(i);
        }

        // Return BLOB image after conversion
        return new Blob([uInt8Array], { type: imageType });
      }

      const link = document.createElement("a");
      link.setAttribute("download", `${fileName}.pdf`); //or any other extension
      link.href = URL.createObjectURL(convertBase64ToBlob(url))
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style>
.bg-grey {
  background-color: #f9f9f9;
}
.text-total-bold {
  font-weight: 700;
  font-size: 14px !important;
}
.e-image {
  width: 60px;
  object-fit: contain;
}
@media screen and (max-width: 768px) {
  .d-xs-none {
    display: none;
  }
  .text-price-bold {
    font-size: 14px;
  }
  .e-image {
    width: 35px;
    object-fit: contain;
  }
}
</style>
