function view(path) {
  return require('./'+path).default;
}

export { view };

export default {
  Auth: require('./auth').default,
  Dashboard: require('./Dashboard').default,
  User: require('./user').default,
  Transfer: require('./transfer').default,
  Certif: require('./certificate').default,
  Contract: require('./contract').default,
  UserManagement: require('./user-management').default,
  Subs: require('./subscription').default,
  Errors: require('./errors').default,
};
