<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <!-- Sidenav / Navigation drawer -->
      <v-navigation-drawer
        id="navigasi"
        fixed
        floating
        v-model="drawer"
        :permanent="$vuetify.breakpoint.mdAndUp"
        :temporary="!$vuetify.breakpoint.mdAndUp"
        class="elevation-1 bg-navy"
      >
        <img
          src="@/assets/images/logo.png"
          alt="Avatar"
          class="ma-3 ml-5"
          style="width: 150px;"
        />
        <!-- <v-spacer class="mt-16"></v-spacer> -->

        <!-- <v-list flat>
          <template v-for="item in items">
            <v-list-item
              active-class="menu-active"
              class="transparent fs-14"
              :key="item.title"
              :to="item.link"
              @click="pageChanged(0, item)"
            >
              <v-list-item-icon>
                  <img src="@/assets/images/home.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list> -->

        <!-- <v-divider style="border-color:#0C3664;"></v-divider> -->

        <v-list flat class="d-none">
          <p
            class="mb-1 pt-2 pl-6 font-weight-medium fs-12 text-blue-menu"
            style="letter-spacing: 0.25em;"
          >
            E-METERAI
          </p>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-icon>
                <img src="@/assets/images/withdraw 1.svg" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Receive Money
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="item in receive">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-icon>
                <img src="@/assets/images/money-bag 1.svg" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Send Money
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="item in trans">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
          <template v-for="item in history">
            <v-list-item
              active-class="menu-active"
              class="transparent text-semi fs-14 d-none"
              :key="item.title"
              :to="item.link"
              @click="pageChanged(0, item)"
            >
              <!-- active-class="menu-active" -->
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

        <!-- <v-divider style="border-color:#0C3664;"></v-divider> -->

        <v-list flat>
          <p
            class="mb-1 pt-2 pl-6 font-weight-medium fs-12 text-blue-menu"
            style="letter-spacing: 0.25em;"
          >
            <!-- E-CONTRACT -->
          </p>
          <template>
            <v-list-item
              active-class="menu-active"
              exact-active-class="menu-active"
              class="transparent fs-14 d-none"
              :to="{ name: 'list-certificate' }"
            >
              <v-list-item-icon>
                <img src="@/assets/images/icon-certif.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  Certificate
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              exact-active-class="menu-active"
              active-class="menu-active"
              class="transparent fs-14 d-none"
              :to="{ name: 'list-template' }"
            >
              <v-list-item-icon>
                <img src="@/assets/images/icon-temp.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  Template
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              exact-active-class="menu-active"
              active-class="menu-active"
              class="transparent fs-14 mb-2"
              :to="{ name: 'dashboard' }"
            >
              <v-list-item-icon>
                <img src="@/assets/images/home.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  Beranda
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              exact-active-class="menu-active"
              active-class="menu-active"
              class="transparent fs-14 mb-2"
              :to="{ name: 'inventory-products' }"
            >
              <v-list-item-icon>
                <img src="@/assets/images/wallet-minus.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title data-v-step="1">
                  Inventori
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group :value="false">
              <template v-slot:activator>
                <v-list-item-icon>
                  <img src="@/assets/images/presention-chart.svg" />
                  <!-- <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_2056_21136)">
                      <path
                        d="M6.64453 9.7207H4.14258C3.68952 9.7207 3.32227 10.088 3.32227 10.541C3.32227 10.9941 3.68952 11.3613 4.14258 11.3613H6.64453C7.09759 11.3613 7.46484 10.9941 7.46484 10.541C7.46484 10.088 7.09759 9.7207 6.64453 9.7207Z"
                        fill="#e0e0e0"
                      />
                      <path
                        d="M6.64453 12.9199H4.14258C3.68952 12.9199 3.32227 13.2872 3.32227 13.7402C3.32227 14.1933 3.68952 14.5605 4.14258 14.5605H6.64453C7.09759 14.5605 7.46484 14.1933 7.46484 13.7402C7.46484 13.2872 7.09759 12.9199 6.64453 12.9199Z"
                        fill="#e0e0e0"
                      />
                      <path
                        d="M6.64453 16.0781H4.14258C3.68952 16.0781 3.32227 16.4454 3.32227 16.8984C3.32227 17.3515 3.68952 17.7188 4.14258 17.7188H6.64453C7.09759 17.7188 7.46484 17.3515 7.46484 16.8984C7.46484 16.4454 7.09759 16.0781 6.64453 16.0781Z"
                        fill="#e0e0e0"
                      />
                      <path
                        d="M11.9766 16.0781H9.47461C9.02155 16.0781 8.6543 16.4454 8.6543 16.8984C8.6543 17.3515 9.02155 17.7188 9.47461 17.7188H11.9766C12.4296 17.7188 12.7969 17.3515 12.7969 16.8984C12.7969 16.4454 12.4296 16.0781 11.9766 16.0781Z"
                        fill="#e0e0e0"
                      />
                      <path
                        d="M11.9765 3.28125H4.14258C3.68952 3.28125 3.32227 3.6485 3.32227 4.10156V7.34179C3.32227 7.79485 3.68952 8.16211 4.14258 8.16211H11.9765C12.4296 8.16211 12.7968 7.79485 12.7968 7.34179V4.10156C12.7968 3.6485 12.4296 3.28125 11.9765 3.28125ZM11.1562 6.52148H4.96289V4.92187H11.1562V6.52148Z"
                        fill="#e0e0e0"
                      />
                      <path
                        d="M20.1797 14.4374C20.6327 14.4374 21 14.0702 21 13.6171V11.5663C21 11.1133 20.6327 10.746 20.1797 10.746H16.0782V0.820312C16.0782 0.367254 15.7109 0 15.2579 0H0.820436C0.367418 0 0.000123047 0.367254 0.000123047 0.820312L0 17.7185C0 19.5277 1.47193 20.9998 3.28125 20.9998L17.7187 20.9999C17.7249 20.9999 17.7312 20.9998 17.7375 20.9997C18.6069 20.9949 19.4235 20.6541 20.0389 20.0388C20.6587 19.4191 21 18.595 21 17.7185C21 17.2654 20.6327 16.8982 20.1797 16.8982C19.7266 16.8982 19.3594 17.2654 19.3594 17.7185C19.3594 18.1568 19.1887 18.5688 18.8788 18.8787C18.5689 19.1885 18.157 19.3591 17.7188 19.3591C17.7187 19.3591 17.7186 19.3591 17.7186 19.3591C16.8141 19.359 16.0781 18.6231 16.0781 17.7185V12.3867H19.3594V13.6171C19.3594 14.0702 19.7267 14.4374 20.1797 14.4374ZM3.28125 19.3592C2.37661 19.3591 1.64062 18.6231 1.64062 17.7185L1.64075 1.64062H14.4375V17.7185C14.4375 18.3158 14.598 18.8763 14.878 19.3593L3.28125 19.3592Z"
                        fill="#e0e0e0"
                      />
                      <path
                        d="M11.9766 9.7207H9.47461C9.02155 9.7207 8.6543 10.088 8.6543 10.541C8.6543 10.9941 9.02155 11.3613 9.47461 11.3613H11.9766C12.4296 11.3613 12.7969 10.9941 12.7969 10.541C12.7969 10.088 12.4296 9.7207 11.9766 9.7207Z"
                        fill="#e0e0e0"
                      />
                      <path
                        d="M11.9766 12.9199H9.47461C9.02155 12.9199 8.6543 13.2872 8.6543 13.7402C8.6543 14.1933 9.02155 14.5605 9.47461 14.5605H11.9766C12.4296 14.5605 12.7969 14.1933 12.7969 13.7402C12.7969 13.2872 12.4296 12.9199 11.9766 12.9199Z"
                        fill="#e0e0e0"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2056_21136">
                        <rect width="21" height="21" fill="white" />
                      </clipPath>
                    </defs>
                  </svg> -->
                </v-list-item-icon>
                <v-list-item-content data-v-step="2" class="mb-2">
                  <v-list-item-title>
                    Laporan
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="item in report">
                <v-list-item
                  active-class="menu-active"
                  class="transparent fs-14 mb-2 mt-2"
                  :key="item.title"
                  :to="item.link"
                  @click="pageChanged(0, item)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-body-1">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-group>

            <v-list-item
              exact-active-class="menu-active"
              active-class="menu-active"
              class="transparent fs-14 mb-2"
              :to="{ name: 'user-management' }"
            >
              <v-list-item-icon>
                <img src="@/assets/images/profile-2user.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title data-v-step="0">
                  Pengguna
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="false"
              exact-active-class="menu-active"
              active-class="menu-active"
              class="transparent fs-14 mb-2"
              :to="{ name: 'api-setting' }"
            >
              <v-list-item-icon>
                <img src="@/assets/images/candle.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  Pengaturan API
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              exact-active-class="menu-active"
              active-class="menu-active"
              class="transparent fs-14 mb-2"
              @click="navigate()"
              :to="{ name: 'api-docs' }"
            >
              <v-list-item-icon>
                <img src="@/assets/images/document-code-2.svg" />
                <!-- <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg> -->
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  Dokumentasi API
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- <v-list-item
              exact-active-class="menu-active"
              active-class="menu-active"
              class="transparent fs-16 pointer"
            >
              <v-list-item-icon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
              </v-list-item-icon>

              <v-list-item-content link @click="logout">
                <v-list-item-title>
                  Logout
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
          </template>
          <br />
          <br />
          <template>
            <div class="pa-2">
              <v-card
                outlined
                class="ma-3"
                style="border-radius:6px;"
                data-v-step="2"
              >
                <img
                  src="@/assets/images/momofin-go.png"
                  style="max-width: 150px;"
                />
                <v-card-title class="pt-0">
                  {{ dataCount.name }}
                  <v-spacer />
                  <v-chip label small>
                    Max {{ dataCount.max_user }} Pengguna
                  </v-chip>
                </v-card-title>
                <v-card-text>
                  <v-row align="center">
                    <v-col class="text-subtitle-1" cols="6">
                      Pengguna
                    </v-col>
                    <v-col class="text-subtitle-1 text-right" cols="6">
                      {{ dataCount.total_user }}/{{ dataCount.max_user }}
                    </v-col>
                  </v-row>
                  <v-progress-linear
                    color="orange darken-2"
                    rounded
                    v-model="percentCount"
                  ></v-progress-linear>
                  <v-btn
                    outlined
                    block
                    class="mt-4"
                    style="border-radius:6px;"
                    to="/dashboard#package"
                  >
                    <span style="color:#CD7B2E;">
                      <v-icon left>ri-vip-crown-line</v-icon>
                      Tingkatkan
                    </span>
                  </v-btn>
                  <v-btn
                    text
                    block
                    class="mt-4"
                    style="border-radius:6px;"
                    to="/history-invoice"
                  >
                    <span style="color:#475569;">
                      Riwayat Paket
                    </span>
                  </v-btn>
                </v-card-text>
              </v-card>
              <v-list-item
                exact-active-class="menu-active"
                active-class="menu-active"
                class="transparent fs-16 pointer"
              >
                <v-list-item-icon>
                  <v-icon>ri-logout-box-line</v-icon>
                </v-list-item-icon>

                <v-list-item-content link @click="logout">
                  <v-list-item-title>
                    Logout
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </template>
        </v-list>
      </v-navigation-drawer>

      <!-- Topnav / App bar -->
      <v-main
        id="tool"
        class="background"
        :style="{ marginLeft: $vuetify.breakpoint.mdAndUp ? '256px' : 0 }"
      >
        <v-app-bar
          id="tool"
          color="background bg-navy"
          :style="{ marginLeft: $vuetify.breakpoint.mdAndUp ? '256px' : 0 }"
          flat
          fixed
          class="bg-navy"
        >
          <v-app-bar-nav-icon
            v-if="!$vuetify.breakpoint.mdAndUp"
            class="ma-2"
            @click="drawer = true"
          ></v-app-bar-nav-icon>
          <v-spacer v-if="!$vuetify.breakpoint.mdAndUp"></v-spacer>
          <img
            v-if="!$vuetify.breakpoint.mdAndUp"
            src="@/assets/images/logo.png"
            alt="Avatar"
            class="ma-3 ml-5"
            style="width: 150px;"
          />

          <!-- <v-toolbar-title>
            <h3 class="font-weight-regular">{{ pageTitle }}</h3>
          </v-toolbar-title> -->
          <!-- <v-text-field
            outlined
            append-icon="$search"
            class="pt-0 mt-0 mr-4 mx-width-85 d-none d-lg-block"
            placeholder="Search"
            solo
            flat
          ></v-text-field> -->

          <v-spacer></v-spacer>

          <!-- <div class="d-none d-lg-block">
            <v-menu offset-y>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  color="white"
                  class="mx-2 my-3"
                  small
                  icon
                  elevation="1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>ri-questionnaire-line</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item class="fs-1">
                  <v-list-item-title
                    v-text="'Tidak ada notifikasi'"
                  ></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-divider
              vertical
              class="my-auto"
              style="height: 40px; min-height: auto"
            ></v-divider>
          </div> -->
          <!-- <div class="d-none d-lg-block">
            <v-menu offset-y>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  color="white"
                  class="mx-2 my-3"
                  small
                  icon
                  elevation="1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>$notification</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item class="fs-1">
                  <v-list-item-title
                    v-text="'Tidak ada notifikasi'"
                  ></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-divider
              vertical
              class="my-auto"
              style="height: 40px; min-height: auto"
            ></v-divider>
          </div> -->
          <v-btn
            class="mr-4"
            v-if="$route.path == '/dashboard'"
            icon
            @click="show_welcome = true"
          >
            <v-icon>ri-book-read-fill</v-icon>
          </v-btn>

          <v-btn
            class="d-none d-sm-flex"
            outlined
            elevation="1"
            style="border-radius: 6px;"
            :to="{ name: 'dashboard', hash: '#package' }"
          >
            <span style="color:#CD7B2E;">
              <v-icon left>ri-vip-crown-line</v-icon>
              Tingkatkan
            </span>
          </v-btn>
          <!-- <v-badge
            bordered
            color="error"
            overlap
            style="font-size:10px;"
            dot
          >
            <v-icon class="mx-2" @click="notif = !notif">ri-notification-4-fill</v-icon>
          </v-badge> -->
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="ml-4 rounded-pill d-flex align-center "
                v-ripple
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar class="elevation-1 white mr-2" size="40px">
                  <img src="@/assets/images/no-pict.png" alt="Avatar" />
                </v-avatar>
                <span class="font-weight-medium d-none d-sm-inline-block">{{
                  profile_name
                }}</span>
                <v-icon class="mx-2" small>$dropdown</v-icon>
              </div>
            </template>

            <v-list dense>
              <!-- <v-list-item link to="/dashboard">
                <v-list-item-title v-text="'Notifikasi'"></v-list-item-title>
              </v-list-item> -->
              <v-list-item link @click="historyInvoice">
                <v-list-item-title>
                  <v-icon>ri-user-line</v-icon>
                  Pengaturan
                </v-list-item-title>
              </v-list-item>
              <!-- <v-list-item link to="/dashboard">
                <v-list-item-title
                  v-text="''"
                ></v-list-item-title>
              </v-list-item> -->
              <v-list-item link @click="logout">
                <v-list-item-title>
                  <v-icon>ri-logout-box-line</v-icon>
                  Logout
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>

        <!-- notifications -->
        <!-- <v-navigation-drawer
          v-model="notif"
          absolute
          right
          style="top:62px;width:410px;"
          permanent
        >
          <v-list
            nav
            dense
            class="mx-3"
          >
            <v-list-item-action >
              <v-icon class="text-right" color="primary" @click="notif = !notif"
                style="position: absolute; right: 10px; top: 10px;">ri-close-circle-fill</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              <h3>Notifikasi</h3>
              <p class="mt-4">Hari ini</p>
            </v-list-item-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="primary">
                  ri-user-search-fill
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>Permintaan Menambah Pengguna Baru</h3>
                </v-list-item-title>

                <v-list-item-subtitle style="white-space:normal;">Pengajuan permintaan menambah pengguna baru atas nama <b>Naela Ali</b> telah disetujui.</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="primary">ri-arrow-right-s-line</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item-title>
              <p class="my-4">Kemarin</p>
            </v-list-item-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="primary">
                  ri-checkbox-circle-fill
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>Aktivasi Subdomain Berhasil</h3>
                </v-list-item-title>

                <v-list-item-subtitle style="white-space:normal;">Aktivasi subdomain Anda berhasil. Klik pada link subdomain untuk menuju halaman subdomain Perusahaan Anda.</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="primary">ri-arrow-right-s-line</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="primary">
                  ri-checkbox-circle-fill
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>Verifikasi Email Berhasil</h3>
                </v-list-item-title>

                <v-list-item-subtitle style="white-space:normal;">Verifikasi email Anda berhasil dilakukan.</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="primary">ri-arrow-right-s-line</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-navigation-drawer> -->

        <v-container fluid style="margin-top: 64px">
          <div class="content-wrapper mx-2">
            <!-- <v-breadcrumbs class="px-0 pt-0" :items="breadcrumbs">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item>
                  <span v-if="item.disabled">
                    {{ item.text }}
                  </span>
                  <router-link class="text-decoration-none" v-else :to="item.href">
                    {{ item.text }}
                  </router-link>
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs> -->

            <transition name="fade-up">
              <router-view
                v-if="loaded"
                @page-changed="pageChanged"
                :key="$route.fullPath"
              ></router-view>
            </transition>
          </div>
        </v-container>
      </v-main>

      <!-- Welcome modal -->
      <v-dialog v-model="show_welcome" width="480px" max-width="100vw">
        <v-card style="position: relative">
          <v-btn
            x-small
            icon
            outlined
            @click="show_welcome = false"
            style="top: 1rem; right: 1rem; position: absolute"
          >
            <v-icon size="16">ri-close-line</v-icon>
          </v-btn>

          <v-row class="px-4 pt-8" no-gutters>
            <v-col cols="12">
              <div class="text-h6 text-center mb-4">
                Selamat Datang di Admin Panel MomofinGO
              </div>
              <div class="text-center mb-4">
                Saat ini Anda sudah dapat menikmati fitur - fitur yang ada di
                platform MomofinGO. <br /><br />
                Disini Anda bisa:<br />
                1. Melihat subdomain MomofinGO sebagai ruang kerja<br />
                2. Menambahkan/menghapus anggota tim<br />
                3. Membeli kuota EMET & ESGN untuk pembubuhan emeterai dan
                penandatanganan digital<br />
                4. Melihat informasi paket langganan<br /><br />
                Ikuti panduan penggunaan layanan dengan klik tombol Jelajahi
                Produk.
              </div>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row class="pa-4" no-gutters justify="center">
              <v-btn class="mx-2" @click="show_welcome = false"
                >Nanti Saja</v-btn
              >
              <v-btn class="mx-2" color="primary" @click="handleStartTour()"
                >Jelajahi Produk</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-tour name="myTour" :steps="tours">
        <template slot-scope="tour">
          <transition name="fade">
            <v-step
              v-if="tour.steps[tour.currentStep]"
              :key="tour.currentStep"
              :step="tour.steps[tour.currentStep]"
              :previous-step="tour.previousStep"
              :next-step="tour.nextStep"
              :stop="tour.stop"
              :skip="tour.skip"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
            >
              <div slot="actions">
                <div class="d-flex flex-nowrap">
                  <v-btn
                    small
                    text
                    color="white"
                    class="mr-auto"
                    @click="tour.skip"
                  >
                    Tutup
                  </v-btn>

                  <v-btn
                    small
                    v-if="!tour.isFirst"
                    class="ml-2"
                    color="white"
                    @click="tour.previousStep"
                  >
                    Sebelumnya
                  </v-btn>
                  <v-btn
                    small
                    v-if="!tour.isLast"
                    class="ml-2"
                    color="primary"
                    @click="tour.nextStep"
                  >
                    Lanjut
                  </v-btn>
                  <v-btn
                    small
                    v-if="tour.isLast"
                    class="ml-2"
                    color="primary"
                    @click="handleEndTour(tour)"
                  >
                    Akses Sekarang
                  </v-btn>
                </div>
              </div>
            </v-step>
          </transition>
        </template>
      </v-tour>
    </v-app>
  </transition>
</template>

<script>
import menu from "./menu";
import transfer from "./transfer";
import report from "./report";
import contract from "./contract";
import setting from "./setting";
import receive from "./receive";
import history from "./history";
import { getDomain } from "@/model/new-auth";

export default {
  data() {
    return {
      show_welcome: false,
      domain: "",
      profile_name: "",

      breadcrumbs: [],
      loaded: true,
      drawer: null,
      notifications: [1],
      pageTitle: "",
      ex1: { label: "color", val: 25, color: "orange darken-3" },
      dataCount: {},
      attend: false,
      reset: false,
      url: "https://www.docs.momofin.com",
      notif: null,
      group: null,
      akun: {},
      roles: {},
    };
  },
  computed: {
    tours() {
      return [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          content: `<div class="text-h6">Atur Anggota Tim</div>
          <div>Untuk mengatur anggota Tim anda bisa masuk melalui menu pengguna</div>`,
          params: {
            placement: "right",
          },
        },
        {
          target: '[data-v-step="1"]',
          content: `<div class="text-h6">Isi Ulang Saldo</div>
          <div>Untuk membeli saldo EMET & ESGN Tim Anda bisa masuk ke menu Inventori</div>`,
          params: {
            placement: "right",
          },
        },
        // {
        //   target: '[data-v-step="2"]',
        //   content: `<div class="text-h6">Atur Langganan</div>
        //   <div>Untuk mengatur paket langganan Anda dapat melalui menu paket</div>`,
        //   params: {
        //     placement: "right",
        //   },
        // },
        {
          target: '[data-v-step="3"]',
          content: `<div class="text-h6">Tandatangan & Meterai Elektronik</div>
          <div>Mulai penandatanganan digital dengan akses subdomain ruang kerja Anda menggunakan email & kata sandi yang sama saat registrasi</div>`,
          params: {
            placement: "top",
          },
        },
      ];
    },
    items() {
      return menu;
    },
    trans() {
      return transfer;
    },
    report() {
      return report;
    },
    contra() {
      return contract;
    },
    set() {
      return setting;
    },
    receive() {
      return receive;
    },
    history() {
      return history;
    },
    percentCount() {
      const a = (this.dataCount.total_user / this.dataCount.max_user) * 100;
      return a;
    },
  },
  created() {
    this.$root.$on("setDialog", (attend) => {
      this.attend = attend;
      this.getCounter();
      this.getProfil();
      // console.log('test',this.attend)
    });
    this.$root.$on("set-page-title", (pageTitle) => {
      this.pageTitle = pageTitle;
    });
    let path = "/" + this.$route.path.split("/")[1];
    let index = this._.findIndex(this.items, { link: path });
    if (index != -1) {
      this.pageTitle = this.items[index].title;
      this.breadcrumbs.push({
        text: this.items[index].title,
        disabled: true,
        href: path,
      });
    }
    this.getCounter();
    this.getProfil();
  },
  mounted() {
    this.$root.$on("updateName", (name) => {
      this.profile_name = name;
    });
    this.getSubDomain();
  },
  methods: {
    getSubDomain() {
      getDomain()
        .then((res) => res.data)
        .then((res) => {
          this.domain = res.data.domain;
        });
      this.axios.get(`/auth/dashboard/profile`).then((res) => {
        let data = res.data.data;
        this.profile_name = data.name;
        this.domain = data.domain;
      });
    },
    handleEndTour(tour) {
      tour.stop();
      const a = document.createElement("a");
      a.href = `https://${this.domain}`;
      a.target = "_blank";
      a.click();
      a.remove();
    },
    handleStartTour() {
      this.$tours["myTour"].start();
      this.show_welcome = false;
    },
    navigate() {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = "https://www.docs.momofin.com";
      a.click();
    },
    getCounter() {
      this.axios.get("/subscription/count").then((response) => {
        let res = response.data.data;
        this.dataCount = res;
      });
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
    historyInvoice() {
      this.$router.push("/profile");
    },
    updateLocation(item) {
      // this.pageChanged(0, item);
      this.pageTitle = item.title;
    },
    pageChanged(segment, location = null) {
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }

      document.title =
        this.breadcrumbs
          .slice()
          .reverse()
          .map((val) => val.text)
          .join(" / ") +
        " - " +
        process.env.VUE_APP_TITLE;
    },
    getProfil() {
      return this.axios.get(`/auth/users/profile`).then((res) => {
        // console.log(res)
        this.akun = res.data.data;
        this.roles = this.akun.roles;

        let data = [];
        let id = null;
        if (localStorage.getItem("show_welcome") != null) {
          data = JSON.parse(atob(localStorage.getItem("show_welcome")));
          id = data.find((v) => v.id == this.akun._id);
        }
        if (!(id && id.show_welcome == false)) {
          const user = { id: this.akun._id, show_welcome: false };
          data.push(user);
          localStorage.setItem("show_welcome", btoa(JSON.stringify(data)));
          setTimeout(() => {
            if (this.$route.path == "/dashboard") this.show_welcome = true;
          }, 1e3);
        }
      });
    },
  },
};
</script>

<style>
.fs-1 {
  padding: 0 8px !important;
}
.fs-1 .v-list-item__title {
  font-size: 1rem !important;
  line-height: 1.25 !important;
}
.pointer:hover {
  cursor: pointer;
}
</style>
