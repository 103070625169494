<template>
  <v-card rounded="lg">
    <v-card-title> Dashboard </v-card-title>

    <v-card-text>
      <div class="d-flex">
        <v-btn color="primary" outlined rounded @click="logout">
          Logout
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  mounted() {
    // console.log(this.$store.getters.isLoggedIn);
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>

<style>
</style>