<template>
  <v-card rounded="lg" class="transparent">
    <v-card class="pricing-card">
      <section class="border-0">
        <div class="card-header__wrapper border-0">
          <div class="card-header__item-group border-0" style="width: 100%">
            <div class="card-header__card">
              <div class="mx-auto">
                <h3 class="card-header__card-title text-uppercase">TOTAL EMET</h3>
                <h4 class="card-header__card-subtitle">
                  {{ emet.stock }} <span class="text-uppercase" style="color: #FBA733"> emet</span>
                </h4>
              </div>
            </div>
            <div class="card-header__card">
              <div class="mx-auto">
                <h3 class="card-header__card-title text-uppercase">TOTAL ESGN</h3>
                <h4 class="card-header__card-subtitle">
                  {{ report.stock }}
                  <span class="text-uppercase" style="color: #25BCD6"> esgn</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- <div class="pricing-header pt-0">
        <ul class="pricing-header__tabs">
          <li>
            <button
              :class="tabs === 'all' && 'font-weight-bold'"
              @click="changeTab('all')"
            >
              Semua
            </button>
          </li>
          <li>
            <button
              :class="tabs === 'single' && 'font-weight-bold'"
              @click="changeTab('single')"
            >
              Beli Satuan
            </button>
          </li>
          <li>
            <button
              :class="tabs === 'bundling' && 'font-weight-bold'"
              class="mr-2"
              @click="changeTab('bundling')"
            >
              Paket Bundling
            </button>
            <v-chip color="success" outlined x-small>Best Value</v-chip>
          </li>
        </ul>
        <div>
          <v-text-field
            outlined
            append-icon="$search"
            class="mt-1 ms-2 mx-width-85 float-right "
            solo
            flat
            style="max-width: 334px; background-color: white;"
            placeholder="Cari Produk"
          />
        </div>
      </div> -->

      <v-divider></v-divider>

      <div v-if="tabs === 'all'">
        <section class="pricing-wrapper">
          <div v-for="(pricing, index) in pricings" :key="pricing.id">
            <div class="pricing-card">
              <div>
                <div class="pricing-card-header">
                  <img
                    v-if="pricing.name === 'e-Meterai'"
                    class="pricing-card-header__img"
                    src="@/assets/images/emet.png"
                    alt="icon"
                    draggable="false"
                    loading="lazy"
                    width="64"
                  />
                  <img
                    v-else
                    class="pricing-card-header__img"
                    src="@/assets/images/e-sign.png"
                    alt="icon"
                    draggable="false"
                    loading="lazy"
                  />
                  <div>
                    <h1 class="pricing-card-header__title">
                      {{ pricing.unit }}
                    </h1>
                    <p class="pricing-header__price text-body-1">
                      Rp. {{ formatToPrice(pricing.price) }}
                    </p>
                  </div>
                </div>

                <!-- <v-divider></v-divider> -->

                <div class="pricing-card-feature">
                  <ul
                    class="pricing-card-feature-list"
                    style="border-top: 0px;padding: 0px!important;"
                  >
                    <li
                      v-for="(facilities, index) in pricing.description_company"
                      :key="index"
                      class="pricing-card-feature-item"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="pricing-card-feature-item__icon"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <p class="pricing-card-feature-item__title text-capitalize">
                        {{ facilities }}
                        <!-- {{ facilities.name }}, {{ facilities.price }} -->
                      </p>
                    </li>
                  </ul>
                </div>

                <v-alert color="#F1F5F9" dense class="text-body-1"
                  >Biaya platform per item
                  {{ formatIdr(pricing.platform_fee) }}
                </v-alert>

                <!-- <v-divider></v-divider> -->

                <p
                  class="pricing-card-feature-item__title text-left text-black font-weight-medium mb-3"
                >
                  Pilih Jumlah Token
                </p>
                <v-alert
                  dense
                  outlined
                  type="error"
                  style="border:0px solid !important;background: #F2FAFF!important;"
                  class="mt-3 text-body-1"
                  color="#94A3B8"
                >
                  Minimal pembelian 10 token
                </v-alert>
                <v-container class="pa-1">
                  <v-item-group v-model="pricing.qty">
                    <v-row>
                      <v-col v-for="(item, i) in items" :key="`item-${i}`" cols="4" md="4" sm="6">
                        <v-item>
                          <v-btn
                            color="primary"
                            dark
                            :outlined="pricing.qty != item.value"
                            @click="pricing.qty = item.value"
                            block
                            class="rounded-lg"
                          >
                            {{ item.value }}
                          </v-btn>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-item-group>
                </v-container>
                <div class="pricing-card-counter-price" style="align-items: center;">
                  <div class="d-flex" style="align-items: baseline;">
                    <h4>
                      <span v-if="pricing.title === 'e-Meterai'" style="color: #FBA733"
                        >EMET{{ index }}</span
                      >
                      <span v-if="pricing.title === 'e-signature'" style="color: #25BCD6"
                        >ESGN{{ index }}</span
                      >
                    </h4>
                  </div>
                  <p class="text-body-1 font-weight-medium text-muted">
                    Atau Masukkan Jumlah Token disini
                  </p>
                  <br />
                  <div class="d-flex align-top" style="margin: 0 auto;text-align: center;">
                    <v-btn
                      min-width="40"
                      width="40"
                      height="40"
                      @click="pricing.qty--"
                      :disabled="pricing.qty === 0"
                    >
                      <v-icon>ri-subtract-line</v-icon>
                    </v-btn>
                    <v-text-field
                      class="w-full-important"
                      type="number"
                      v-model.number="pricing.qty"
                      min="10"
                      placeholder="Minimal 10 token"
                      :rules="[numberRule]"
                    >
                    </v-text-field>
                    <v-btn min-width="40" width="40" height="40" @click="pricing.qty++">
                      <v-icon>ri-add-line</v-icon>
                    </v-btn>
                  </div>
                </div>

                <!-- <v-divider></v-divider> -->
                <div class="pricing-card-price my-4">
                  <h3 v-if="pricing.qty > 10000" class="primary--text font-weight-light">
                    Untuk pembelian lebih dari 10.000 token, silahkan hubungi customer service kami.
                  </h3>
                  <template v-else>
                    <template v-if="pricing.qty > 0">
                      <v-row class="mb-2" no-gutters justify-sm="space-between">
                        <v-col>{{ pricing.qty }} {{ pricing.unit }}</v-col>
                        <v-col class="text-right"
                          >{{ pricing.qty }} x {{ formatIdr(pricing.price) }}</v-col
                        >
                      </v-row>
                      <v-row class="mb-2" no-gutters justify-sm="space-between">
                        <v-col>Biaya platform</v-col>
                        <v-col class="text-right"
                          >{{ pricing.qty }} x {{ formatIdr(pricing.platform_fee) }}</v-col
                        >
                      </v-row>
                      <v-divider class="mb-2" />
                      <v-row class="text-h6" no-gutters justify-sm="space-between">
                        <v-col>Total</v-col>
                        <v-col class="text-right">
                          {{ formatIdr((pricing.price + pricing.platform_fee) * pricing.qty) }}
                        </v-col>
                      </v-row>
                    </template>
                  </template>
                </div>
              </div>

              <v-divider></v-divider>

              <v-card-actions v-if="reload" class="pricing-card-action pb-0">
                <v-btn
                  v-if="pricing.qty > 10000"
                  class="pricing-card-action__button"
                  width="100%"
                  height="90px"
                  color="primary"
                  @click="linkWa()"
                  :loading="pricing.loading"
                  :disabled="(isLoading && !pricing.loading) || pricing.qty === 0"
                >
                  Hubungi CS
                </v-btn>
                <v-btn
                  v-else
                  class="pricing-card-action__button"
                  width="100%"
                  height="90px"
                  color="primary"
                  @click="handleClick(pricing)"
                  :loading="pricing.loading"
                  :disabled="(isLoading && !pricing.loading) || pricing.qty < 10"
                >
                  Beli
                </v-btn>
              </v-card-actions>
            </div>
          </div>
        </section>
      </div>
    </v-card>

    <v-snackbar v-model="hasError">
      {{ message }} <br />
      <p class="text-subtitle-2">- {{ messageValidation }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="hasError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="hasFormError">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="hasFormError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { onMounted, reactive, ref, toRefs } from '@vue/composition-api';
// import axios from "axios";
import useFetch from '../../../hooks/useFetch';
import api from '../../../store/api';
import { formatPrice } from '../../../lib/formatDate';

export default {
  data() {
    return {
      report: {},
      emet: {},
      numberRule: (v) => {
        if (!v) return 'Tidak boleh kosong';
        // if (!isNaN(parseFloat(v)) && v >= 1) return true;
        if (!isNaN(parseFloat(v)) && v >= 10) return true;
        return 'Pembelian kurang dari 10';
      },
      items: [
        {
          value: 10,
        },
        {
          value: 25,
        },
        {
          value: 50,
        },
        {
          value: 100,
        },
        {
          value: 500,
        },
        {
          value: 1000,
        },
      ],
    };
  },
  setup() {
    const { fetchData } = useFetch();
    const state = reactive({
      name: 'Beli Produk e-Contract',
      title: 'Pilih Paket yang anda inginkan',
      pricings: [],
      isLoading: false,
      addToCartDisabled: false,
      tabs: 'all',
      createOrder: {
        product_id: 0,
        qty: 0,
      },
      submitting: false,
      selectedItem: {},
      message: '',
      messageValidation: '',
      hasError: false,
      hasFormError: false,
    });

    const reload = ref(true);

    const changeTab = (val) => {
      state.tabs = val;
    };

    onMounted(async () => {
      state.isLoading = true;
      // let {data} = await axios.get("https://api.momofin.com/v1/econtract/inventory/all");
      // state.pricings = data.data;
      getDataInventory();
      state.isLoading = false;
    });

    const getDataInventory = async () => {
      state.isLoading = true;
      let data = await fetchData({
        url: api.all_inventory,
        method: 'get',
      });
      if (!data.hasError) {
        state.pricings = data.response.data;
      } else {
        state.hasError = true;
        state.message = data.message;
      }
      state.isLoading = false;
    };

    const addToCart = (id) => {
      state.selectedItem = state.pricings[id];
      if (!state.selectedItem.qty) {
        state.selectedItem.qty = 1;
      } else {
        state.selectedItem.qty += 1;
      }
      state.pricings[id] = state.selectedItem;
      // console.log(state.pricings[id]);
    };

    const reduceToCart = (id) => {
      state.selectedItem = state.pricings[id];
      state.selectedItem.qty -= 1;
      state.pricings[id] = state.selectedItem;
    };

    const handleClick = async (pricing) => {
      pricing.loading = true;
      state.isLoading = true;
      reload.value = false;
      reload.value = true;

      state.createOrder.product_id = pricing.id;
      state.createOrder.qty = pricing.qty;
      let res = await fetchData({
        url: `econtract/inventory/client/order_v2`,
        method: 'POST',
        data: state.createOrder,
      }).finally(() => {
        pricing.loading = false;
        state.isLoading = false;
        reload.value = false;
        reload.value = true;
      });
      if (!res.hasError) {
        pricing.loading = false;
        state.message = 'Berhasil checkout';
        let url = res.response.data.invoice.invoice_url;
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.click();
        document.removeChild(anchor);
      } else {
        state.hasError = true;
        pricing.loading = false;
        state.message = res.response.data.msg;
        state.messageValidation = res.response.data.error[0].message;
      }
    };

    const formatToPrice = (value) => {
      return formatPrice(value);
    };

    return {
      formatToPrice,
      changeTab,
      addToCart,
      reduceToCart,
      handleClick,
      getDataInventory,
      ...toRefs(state),
      reload,
    };
  },
  mounted() {
    this.getReport();
  },
  methods: {
    formatIdr(number) {
      try {
        return parseInt(number).toLocaleString('id-ID', {
          style: 'currency',
          currency: 'IDR',
        });
      } catch (error) {
        return error.message;
      }
    },
    getReport() {
      this.axios.get(`/econtract/web_app/client_balance_summary`).then((res) => {
        this.report = res.data.data.esgn;
        this.emet = res.data.data.emet;
      });
    },
    linkWa() {
      window.open('https://wa.me/628112955660', '_blank');
    },
  },
};
</script>

<style scoped>
.w-full-important .v-input__slot {
  background: red !important;
}
</style>

<style scoped lang="scss">
p {
  margin: 0;
  padding: 0;
}
.pricing-wrapper {
  padding: 0 10px;
  margin: 40px 0;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  @media (min-width: 768px) {
    gap: 43px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
.pricing {
  &-header {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    &__tabs {
      list-style: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      max-width: 400px;
      li {
        font-size: small;
      }
    }
  }
  &-card {
    border-radius: 12px;
    padding: 20px;
    border: solid 1px #e0e0e0 !important;
    &-header {
      display: flex;
      place-items: center;
      padding: 0 0 20px 0;
      // padding: 20px 0;
      padding-top: 10;
      &__img {
        margin-right: 0.5rem;
      }
      &__title {
        font-size: 1rem;
      }
      &__price {
        font-size: 0.5rem;
        font-weight: bold;
      }
    }
    &-feature {
      &-list {
        border-top: solid #e0e0e0 1px;
        list-style: none;
        min-height: 80px;
        padding: 20px 0 !important;
      }
      &-item {
        display: flex;
        place-items: center;
        padding: 5px;
        &__title {
          margin: 0;
          margin-bottom: 0 !important;
        }
        &__icon {
          margin-right: 5px;
          width: 20px;
          color: #9ad4fb;
        }
      }
    }
    &-counter-price {
      padding: 20px 0;
      width: 100%;
      /*display: flex;*/
      justify-content: space-between;
    }
    &-action {
      padding: 20px 0;
      &__button {
        height: 44px !important;
        border-radius: 6px;
      }
    }
  }
}

.card-header__item-group {
  box-shadow: unset !important;
}

@media (max-width: 768px) {
  .card-header__item-group {
    grid-template-columns: auto !important;
    padding: 31px 10px !important;
  }
}
</style>
